import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const NetworkVulnerabilityScanningPageTemplate = ({
  seo,
  hero,
  contactus,
  schedulesection,
  assesmentsection,
  aboutsection,
  wcysection,
  wcbsection,
  fixedimagesection
}) => (
    <div className="custompages">
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <SubTitle textAlign="center" title={contactus.title} />
            </div>
          </div>
          <div className="columns">
            <div className="column  has-text-centered">
              <a href={contactus.buttonlink} >
                <button class="btn-light">{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>


      <section className="section " style={{ background: `${schedulesection.sectionbg}` }}>
        <div className="container scedulesection fontfamilynormal">
          <div >
            <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{schedulesection.title}</p>
            <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{schedulesection.secondtitle}</p>

            <div className="columns" >
              <div className="column is-6 " style={{ marginTop: '15px' }}>

                <a href={schedulesection.buttonlink} >
                  <button class="btn-light">{schedulesection.buttontxt}</button>
                </a>


              </div>
              <div className="column is-2 mobile-center has-text-centered">

              </div>
              <div className="column  mobile-center has-text-centered hero-image">
                <div style={{ maxWidth: 300, height: 30 }}>
                  <PreviewCompatibleImage imageInfo={aboutsection.image} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>



      <section className="section" style={{ background: `${assesmentsection.sectionbg}` }}>
        <div className="container assesmentsection">
          <div style={{
            marginTop: "1rem"
          }}>
            <div className="columns" >
              <div className="column is-9">
                < Title title={assesmentsection.title} color={assesmentsection.textcolor} />
              </div>
            </div>

            {
              assesmentsection.descriptions1.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }
            <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#fff' }}>
              {
                assesmentsection.orderList1.list.map((order, i) => {
                  return (
                    <li style={{ marginTop: '1rem' }}>
                      {order.title}
                    </li>
                  )
                })
              }
            </ul>

            {
              assesmentsection.descriptions2.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }
            <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#fff' }}>
              {
                assesmentsection.orderList2.list.map((order, i) => {
                  return (
                    <li style={{ marginTop: '1rem' }}>
                      {order.title}
                    </li>
                  )
                })
              }
            </ul>

            {
              assesmentsection.descriptions3.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }




            <div className="columns">
              <div className="column is-8  " style={{ marginTop: "1.5rem" }}>
                <a href={assesmentsection.link} >
                  <button class="">{assesmentsection.txt}</button>
                </a>
              </div>
            </div>




          </div>
        </div>
      </section >
      <section className="section" style={{ margin: "0rem 0 2rem", background: `${wcbsection.sectionbg}` }}>
        <div className="container">
          <div>
            <SubTitle color="#ffffff" title={wcbsection.title} />
            <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{wcbsection.subheader1}</p>
            <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{wcbsection.descriptions1.title}</p>
            {
              wcbsection.descriptions1.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{description.title}</p>
                    <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{description.description}</p>
                  </div>
                )
              })
            }
            <SubTitle color="#ffffff" title={wcbsection.subheader2} margin="1.5rem 0" />
            {
              wcbsection.descriptions2.list.map((desc, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{desc.title}</p>
                  </div>
                )
              })
            }

            <SubTitle color="#ffffff" title={wcbsection.subheader3} margin="1.5rem 0" />
            {
              wcbsection.descriptions3.list.map((desc, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{desc.title}</p>
                  </div>
                )
              })
            }
          </div>


          <div className="columns">
            <div className="column is-8  " style={{ marginTop: "1.5rem" }} >
              <a href={wcbsection.buttonlink} >
                <button class="btn-light">{wcbsection.buttontxt}</button>
              </a>
            </div>
          </div>


        </div>
      </section>
      <Brands />
      <WhyChooseUs
        title="Any specific points or should we use the already available ones?"
        list={[{
          title: 'We are security experts who train others. ',
          para: 'Our network security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations on how to properly assess their networks for security vulnerabilities.'
        }, {
          title: 'We are all developers and we understand code. ',
          para: 'We aren’t only experts in security, we also know how applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews for your applications as well as networks.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Ensure That Your Network is Secure?',
        subtitle: '',
        para: [
          'Let our security engineers scan and test your network for vulnerabilities and potential entry points. We check your system for known vulnerabilities and exploitable programs and scripts to mitigate threats.'

        ],
        linkTitle: 'Talk to Our Experts Now'
      }} />

    </div >
  )

NetworkVulnerabilityScanningPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  schedulesection: PropTypes.object,
  assesmentsection: PropTypes.object,
  aboutsection: PropTypes.object,
  wcysection: PropTypes.object,
  wcbsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const NetworkVulnerabilityScanningPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <NetworkVulnerabilityScanningPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        schedulesection={frontmatter.schedulesection}
        assesmentsection={frontmatter.assesmentsection}
        aboutsection={frontmatter.aboutsection}
        wcysection={frontmatter.wcysection}
        wcbsection={frontmatter.wcbsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

NetworkVulnerabilityScanningPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default NetworkVulnerabilityScanningPage

export const pageQuery = graphql`
  query NetworkVulnerabilityScanningPageTemplate {
        markdownRemark(frontmatter: {templateKey: {eq: "network-vulnerability-scanning" } }) {
        frontmatter {
      seo {
        title
          description
      article_section
    }
        hero{
        title
          secondtitle
      image {
        childImageSharp {
      fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
          midimage{
        alt
            image {
        childImageSharp {
      fluid(maxWidth: 260, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
          sideimage{
        alt
            align
      image {
        childImageSharp {
      fluid(maxWidth: 260, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
  description
  buttontxt
  buttonlink
}
        contactus {
        title
          secondtitle
      buttontxt
      buttonlink
    }
    schedulesection{
      sectionbg
      textcolor
      title
      secondtitle
      buttontxt
      buttonlink
    }
    assesmentsection{
      sectionbg
      textcolor
      title
      descriptions1 {
        list {
          title
          }
       }
       orderList1{
        list {
          title
          }
       }
       descriptions2 {
        list {
          title
          }
       }
       orderList2{
        list {
          title
          }
       }
       descriptions3 {
        list {
          title
          }
       }
      txt
      link
   }
        aboutsection {
        sectionbg
          textcolor
      title
      secondtitle
      subheader
      descriptions
      sidedescriptions
          image {
        childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
        wcysection {
        sectionbg
          textcolor
      title
      secondtitle
      subheader
      descriptions
    }
        wcbsection {
          sectionbg
          title
          secondtitle
          subheader1
          descriptions1 {
          title
            list {
              title
              description
              }
           }
          subheader2
          descriptions2 {
              list {
                title
                }
             }
          subheader3
          descriptions3 {
            list {
              title
              }
           }
          buttontxt
          buttonlink
          buttoncolor
  }
        fixedimagesection {
        title
          secondtitle
      image {
        childImageSharp {
      fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
}
}
}
`
